<template>
  <v-app>
    <AppBar />

    <v-main
      class="scrollable"
    >
      <Hero />
      <About />
      <Services />
      <Stats />
      <Scope />
      <Contact />
      <Footer />
    </v-main>
    <cookie-consent class="text-caption">
      <template slot="message">
        <div class="">{{ $t("cookies") }}</div>
      </template>
      <template slot="button">
        <v-btn color="grey lighten-1 ma-1" rounded x-small elevation="4">
          {{ $t("accept") }}
        </v-btn>
      </template>
    </cookie-consent>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Hero from "./components/Hero";
import About from "./components/About";
import Contact from "./components/Contact";
import Scope from "./components/Scope";
import Stats from "./components/Stats";
import Services from "./components/Services";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    //HelloWorld,
    AppBar,
    Hero,
    About,
    Contact,
    Scope,
    Stats,
    Services,
    Footer,
    //CookieConsent,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
$cookieconstent-padding: 0rem 0 !default;
$cookieconstent-space: 0.5rem 1rem !default;
$cookieconstent-position: fixed !default;
$cookieconstent-background: #ced4da77 !default;
$cookieconstent-z-index: 9999 !default;
$cookieconstent-width: 100% !default;

$cookieconstent-compliance-padding: 0.5rem 2rem !default;
$cookieconstent-compliance-color: #fff !default;
$cookieconstent-compliance-background: #237afc !default;
$cookieconstent-compliance-border: 2px solid currentColor !default;

.cookie-consent-transition {
  &-leave-active {
    transition: transform 0.75s;
    transition-timing-function: cubic-bezier(0.75, 0, 0, 1);
  }
  &-leave-to {
    transform: translateY(100%);
  }
}

.cookie-consent {
  left: 0;
  right: 0;
  bottom: 0;
}

.cookie-consent {
  position: $cookieconstent-position;
  z-index: $cookieconstent-z-index;
  width: $cookieconstent-width;
  padding: $cookieconstent-padding;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background: $cookieconstent-background;

  &-message,
  &-compliance {
    margin: $cookieconstent-space;
  }

  &-compliance {
    cursor: pointer;
    color: $cookieconstent-compliance-color;
    padding: $cookieconstent-compliance-padding;
    background: $cookieconstent-compliance-background;
    border: $cookieconstent-compliance-border;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
.text-h3n {
  font-size: clamp(30px, 6vw, 60px) !important;
}
.text-h4n {
  font-size: clamp(20px, 4vw, 50px) !important;
}
.text {
  font-size: clamp(15px, 1.5vw, 30px) !important;
}
.v-card__text,
.v-card__title {
  font-size: clamp(17px, 1.5vw, 30px) !important;
  line-height: max(1.5vw, 20px) !important;
}
.v-btn--outlined{
  box-shadow: 2px 2px 4px black;
}

</style> 
