export default [
    { icon: "mdi-twitter", link: "http://twitter.com/NattJam" },
    {
        icon: "mdi-linkedin",
        link: "http://linkedin.com/in/natalijajamieson",
    },
    {
        icon: "mdi-xing",
        link: "https://www.xing.com/profile/Natalija_Jamieson",
    },
    {
        icon: "mdi-phone",
        link: "tel:+41796912232",
    },
]