<template>
  <section id="stats">
    <v-parallax
      src="/stats/stats.jpg"
      
    >
      <v-overlay absolute opacity="0.6" color="blue-grey darken-4">
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="(n, index) in Object.keys($t('stats'))"
              :key="index"
              cols="12"
              md="4"
            >
              <div class="text-center">
                <div
                  class="text-h4n font-weight-black mb-4"
                  v-text="$t(`stats.${n}.value`)"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="$t(`stats.${n}.title`)"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
  </section>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "stats",
  props: [],
  mounted() {},
  created() {
    this.DBupdateImage = debounce(this.updateImage, 50);
  },
  data() {
    return {
      image:
        //"https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=scale&crop=top&h=610&w=" +
        "https://images.unsplash.com/photo-1617706076253-40b318277155?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&crop=top&min-h=700&w=" +
        this.$vuetify.breakpoint.width +
        "&q=80",
      //image:"https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=scale&crop=top&h=610&w="+this.$vuetify.breakpoint.width+"&q=80"
    };
  },
  watch: {
    width: function (newVal, oldVal) {
      //this.DBupdateImage();
    },
  },
  methods: {
    updateImage: function () {
      this.image =
        "https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=scale&crop=top&h=610&w=" +
        this.width +
        "&q=80";
    },
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
</style>