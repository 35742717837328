<template>
  <section>
    <v-footer dark padless>
      <v-card
        flat
        tile
        color="#292929"
        class="white--text text-center fullwidth"
      >
        <v-card-text>
          <v-btn
            v-for="dl in sm"
            :key="dl.icon"
            class="mx-4 white--text"
            icon
            :href="dl.link"
            target="_blank"
          >
            <v-icon size="24px">
              {{ dl.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Natalija Jamieson <br />
          Communications Consultant
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          &copy; {{ new Date().getFullYear() }} — <strong>njcc</strong> <br />
        </v-card-text>
      </v-card>
    </v-footer>
  </section>
</template>

<script>
import sm from "./social-links";
export default {
  name: "footerSection",
  props: [],
  mounted() {},
  data() {
    return {
      sm: sm,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.fullwidth {
  width: 100%;
}
</style>