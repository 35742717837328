<template>
  <section id="scope" class="grey lighten-3">
    <div class="py-5"></div>

    <v-container class="text-center">
      <h2 class="text-h4n font-weight-bold mb-3 text-uppercase">
        {{ $t("scope.title") }}
      </h2>

      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-row>
        <v-col
          v-for="(n, index) in Object.keys($t('scope.items'))"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card class="py-12 px-2" color="grey lighten-5 " elevation="7">
            <v-theme-provider dark>
              <div>
                <v-avatar color="grey darken-1" size="88">
                  <v-icon large v-text="$t(`scope.items.${n}.icon`)"></v-icon>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
              class="justify-center font-weight-black "
              v-text="$t(`scope.items.${n}.title`)"
            ></v-card-title>

            <v-card-text
              class="font-weight-light"
              v-text="$t(`scope.items.${n}.text`)"
            >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-5"></div>
  </section>
</template>

<script>
export default {
  name: "scope",
  props: [],
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>