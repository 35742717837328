<template>
  <section id="hero">
    <v-row no-gutters>
      <v-img
        transition="xxx"
        :height="
          'calc(100vh - ' +
          $vuetify.application.bar +
          $vuetify.application.top +
          'px)'
        "
        src="@/assets/img/welcome/welcome.jpg"
        lazy-src="@/assets/img/welcome/welcome_lazy.jpg"
      >
        <!-- <v-overlay absolute opacity="0.3" color="blue-grey darken-4"> -->
        <v-theme-provider dark>
          <v-container fill-height>
            <v-row align="center" class="white--text mx-auto" justify="center">
              <v-col class="white--text text-center" cols="12" tag="h1">
                <span class="font-weight-black text-h4n text-shadow">
                  Natalija Jamieson
                </span>

                <br />

                <span class="font-weight-light text-h3n text-shadow">
                  Communications
                </span>
                <span class="font-weight-light text-h3n text-shadow">
                  Consultant
                </span>
              </v-col>
              <v-btn
                class="align-self-end text-shadow"
                fab
                outlined
                @click="$vuetify.goTo('#about-me')"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
              <!-- Small Screens -->
              <!--  <v-col class="white--text text-center d-md-none" cols="12" tag="h1">
                  <span class="font-weight-black text-h5 text-shadow">
                    Natalija Jamieson
                  </span>

                  <br />

                  <span class="font-weight-light text-h4 text-shadow">
                    Communications
                  </span>
                  <span class="font-weight-light text-h4 text-shadow">
                    Consultant
                  </span>

                </v-col>

                <v-btn
                  class="align-self-end  text-shadow"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn> -->
            </v-row>
          </v-container>
        </v-theme-provider>
        <!-- </v-overlay> -->
      </v-img>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "hero",
  props: [],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
  computed: {},
};
</script>

<style scoped>
.xxx-enter-active {
  transition: opacity 0.3s ease-in-out;
}

.xxx-leave-active {
  transition: none;
}

.xxx-enter,
.xxx-leave,
.xxx-leave-to {
  opacity: 0;
}

.xxx-enter-to {
  opacity: 1;
}

.text-shadow {
  text-shadow: 4px 4px 4px rgb(0, 0, 0);
}

/* .text-h3 {
  font-size: clamp(30px, 10vw, 80px);
}
.text-h4 {
  font-size: clamp(20px, 7vw, 60px);
} */

/* Name max 60px, job 80px
  smallest 20, 30
*/
</style>