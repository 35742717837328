<template>
  <section id="services" class="grey lighten-4">
    <div class="py-5"></div>

    <v-container>
      <h2 class="text-h4n font-weight-bold mb-3 text-uppercase text-center">
        {{ $t("services.title") }}
      </h2>

      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-row>
        <v-col
          v-for="(n, index) in Object.keys($t('scope.items'))"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card class="mx-auto" elevation="7">
            <v-img
              :src="`/services/${index + 1}.jpg`"
              :lazy-src="`/services/${index + 1}_lazy.jpg`"
              class="mb-4 white--text align-end"
              gradient="to top, rgba(6, 7, 15, 0.8), transparent 50%"
              height="275"
              max-width="100%"
            >
              <v-card-title>
                <!--                 <h3
                  class="font-weight-black mb-4 text-uppercase"
                  v-text="$t(`services.items.${n}.title`)"
                ></h3> -->
                {{ $t(`services.items.${n}.title`) }}
              </v-card-title>
            </v-img>

            <v-card-text
              class="font-weight-light mb-5"
              v-text="$t(`services.items.${n}.text`)"
            >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-5"></div>
  </section>
</template>

<script>
export default {
  name: "services",
  props: [],
  mounted() {},
  data() {
    return {
      baseURL: "",
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>