<template>
  <section id="contact">
    <v-sheet color="#333333" dark tag="section" tile>
      <div class="py-5"></div>

      <v-container>
        <h2 class="text-h4n font-weight-bold mb-3 text-uppercase text-center">
          {{ $t("contact.title") }}
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-theme-provider light>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  flat
                  type="text"
                  autocomplete="name"
                  :label="$t('contact.name')"
                  solo
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  flat
                  type="email"
                  autocomplete="email"
                  :label="$t('contact.email')"
                  solo
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="subject"
                  flat
                  type="text"
                  :label="$t('contact.subject')"
                  solo
                  :rules="requiredRules"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="message"
                  flat
                  type="text"
                  :label="$t('contact.message')"
                  solo
                  :rules="requiredRules"
                  required
                ></v-textarea>
              </v-col>
              <v-col
                v-if="response"
                class="text-center text-caption mt-0 pt-0"
                cols="12"
              >
                <v-alert dense :type="type">{{ response }}</v-alert>
              </v-col>

              <v-col class="mx-auto" cols="auto">
                <v-btn
                  color="grey lighten-1"
                  x-large
                  elevation="4"
                  @click="send"
                >
                  {{ $t("contact.submit") }}
                </v-btn>
              </v-col>
              <v-col class="text-center text-caption" cols="12">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                and
                <a href="https://policies.google.com/terms">Terms of Service</a>
                apply.
              </v-col>
            </v-row>
          </v-form>
        </v-theme-provider>
      </v-container>

      <div class="py-5"></div>
    </v-sheet>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "contact",
  props: [],
  mounted() {},
  data() {
    return {
      valid: false,
      //message: false,
      response: "",
      type: "warning",
      name: "",
      email: "",
      subject: "",
      message: "",
      emailRules: [
        (v) => !!v || this.$t("form.emailrequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("form.emailincorrect"),
      ],
      requiredRules: [(v) => !!v || this.$t("form.emailrequired")],
    };
  },
  methods: {
    async send() {
      if (this.$refs.form.validate()) {
        await this.$recaptchaLoaded();

        const token = await this.$recaptcha("contact");

        //send post request to process.php
        var { email, name, subject, message } = this;
        // console.log(email, name, subject, message, token);
        var response = await axios
          .post("https://new.njcc.ch/process.php", {
            email,
            name,
            subject,
            message,
            token,
          })
          .catch((error) => {
            console.log(error);
            this.type = "warning";
            this.response = this.$t("form.mailnotsent");
            setTimeout(() => {
              this.response = "";
            }, 6000);
          });
        //console.log(response);
        if (!response) {
          return;
        }
        console.log(response);

        if (response.data.success) {
          //erfolgsmeldung einblenden
          this.type = "success";
          this.response = this.$t("form.success");
          setTimeout(() => {
            this.response = "";
          }, 6000);
          this.$refs.form.reset();
        } else {
          //negativmeldung einblenden
          var error = response.data.error;
          this.type = "warning";
          this.response = this.$t(`form.${error}`);
          setTimeout(() => {
            this.response = "";
          }, 6000);
        }
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>