<template>
  <section id="about-me" class="">
    <div class="py-5"></div>

    <v-container class="text-center">
      <h2 class="text-h4n font-weight-bold mb-3 text-uppercase">
        {{ $t("about.title") }}
      </h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive
        class="mx-auto text font-weight-light mb-8"
        
      >
        <p v-for="(n, index) in Object.keys($t('about.text'))" :key="index">
          {{ $t(`about.text.${n}`) }}
        </p>
      </v-responsive>
      <v-lazy transition="scale-transition">
        <v-avatar class="elevation-12 mb-12" size="140">
          <v-img src="@/assets/img/nj_small.jpg"></v-img>
        </v-avatar>
      </v-lazy>
      <v-responsive
        class="mx-auto text font-weight-light mb-8"
        max-width="720"
      >
        Natalija Jamieson, Communications Consultant
      </v-responsive>

      <div>
        <v-btn
          v-for="dl in sm"
          :key="dl.icon"
          class="mx-4"
          icon
          :href="dl.link"
          target="_blank"
        >
          <v-icon size="24px">
            {{ dl.icon }}
          </v-icon>
        </v-btn>
      </div>

      <!--           <v-btn color="grey" href="https://vuetifyjs.com" outlined large>
            <span class="grey--text text--darken-1 font-weight-bold">
              Vuetify Documentation
            </span>
          </v-btn> -->
    </v-container>

    <div class="py-5"></div>
  </section>
</template>

<script>
import sm from "./social-links";
export default {
  name: "about",
  props: [],
  mounted() {},
  data() {
    return {
      sm: sm,
    };
  },
  methods: {},
  computed: {
    /*     sm: function () {
      console.log(this.$root.$data.sm)
      return this.$root.$data.sm;
    }, */
  },
};
</script>

<style scoped>
</style>