import 'intersection-observer'
import 'babel-polyfill'
import Vue from 'vue'
import Main from './Main.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import 'vuetify/dist/vuetify.min.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import cookieconsent from 'vue-cookieconsent-component'



Vue.component('cookie-consent', cookieconsent)

Vue.use(VueReCaptcha,
  { siteKey: "6LeiaZAbAAAAAJ6-R15g9p97IFQ4lWAATfr0uJcU" })

Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  render: h => h(Main)
}).$mount('#app')
