<template>
  <v-app-bar app dense color="white">
    <v-btn
      text
      rounded
      :ripple="false"
      @click="$vuetify.goTo('#hero')"
      class="font-weight-black text-subtitle-1"
    >
      njcc
    </v-btn>

    <div class="d-none d-md-flex">
      <v-btn plain @click="$vuetify.goTo('#about-me')">{{
        $t("about.title")
      }}</v-btn>
      <v-btn plain @click="$vuetify.goTo('#services')">{{
        $t("services.title")
      }}</v-btn>
      <v-btn plain @click="$vuetify.goTo('#scope')">{{
        $t("scope.title")
      }}</v-btn>
      <v-btn plain @click="$vuetify.goTo('#contact')">{{
        $t("contact.title")
      }}</v-btn>
    </div>

    <v-spacer></v-spacer>

    <div class="d-none d-md-flex">
      <v-btn v-if="$i18n.locale == 'en'" plain @click="$i18n.locale = 'de'"
        >de</v-btn
      >
      <v-btn v-else plain @click="$i18n.locale = 'en'">en</v-btn>
    </div>

    <v-menu right bottom rounded="lg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="d-md-none">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense rounded>
        <v-list-item @click="$vuetify.goTo('#about-me')">
          <v-list-item-title class="text-uppercase text-button">{{
            $t("about.title")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$vuetify.goTo('#services')">
          <v-list-item-title class="text-uppercase text-button">{{
            $t("services.title")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$vuetify.goTo('#scope')">
          <v-list-item-title class="text-uppercase text-button">{{
            $t("scope.title")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$vuetify.goTo('#contact')">
          <v-list-item-title class="text-uppercase text-button">{{
            $t("contact.title")
          }}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="$i18n.locale == 'en'" @click="$i18n.locale = 'de'">
          <v-list-item-title class="text-uppercase text-button"
            >de</v-list-item-title
          >
          <v-list-item-icon>
            <v-icon> mdi-earth </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-else @click="$i18n.locale = 'en'">
          <v-list-item-title class="text-uppercase text-button"
            >en</v-list-item-title
          >
          <v-list-item-icon>
            <v-icon> mdi-earth </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "appbar",
  props: [],
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>